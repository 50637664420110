import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { filterOrderReportStatApi } from "../../api/order";
import { IOrderReportStatResponse } from "../../interface/order";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";

const ReportStats = () => {
  const { from, to } = useOutletContext<{ from: number, to: number }>()
  const dispatch = useAppDispatch();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [loading, setLoading] = useState(true);
  const [stat, setStat] = useState<IOrderReportStatResponse>({
    avgDistance: 0,
    avgDistanceDriver: 0,
    avgDuration: 0,
    avgTotalAmount: 0,
    avgTotalAmountDriver: 0,
    totalDriver: 0,
    totalTrips: 0,
  });

  const filterOrderReportStat = async (from: number, to: number) => {
    try {
      setLoading(true);
      const { data } = await filterOrderReportStatApi(from, to);
      setStat(data ?? [])

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  useEffect(() => {
    filterOrderReportStat(from, to)
    // eslint-disable-next-line
  }, [from, to]);

  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="d-flex flex-wrap mt-2 mb-4">
            <div className="mr-4">
              <h1 className="mb-1 font-weight-medium font-primary">{stat.totalTrips.toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2
              })}</h1>
              <p className="text-muted mb-0 tx-12">จำนวนเที่ยวทั้งหมด</p>
            </div>
            <div className="mr-4">
              <h1 className="mb-1 font-weight-medium font-primary">{stat.totalDriver.toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2
              })}</h1>
              <p className="text-muted mb-0 tx-12">จำนวนรถ</p>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table card-table-one">
              <thead>
                <tr>
                  <th style={{ width: 50 }}></th>
                  <th>รายการ</th>
                  <th className="text-right">จำนวน</th>
                  <th style={{ width: 150 }} className="text-right">หน่วย</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><strong className="h4 text-muted">01</strong></td>
                  <td>
                    <div className="d-flex">
                      <strong className="h4">ค่าโดยสารเฉลี่ย</strong>
                    </div>
                  </td>
                  <td className="text-right"><strong className="h2 font-primary">{stat.avgTotalAmount.toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2
                  })}</strong></td>
                  <td className="text-right"> บาท/เที่ยว</td>
                </tr>

                {/* <tr>
                  <td><strong className="h4 text-muted">02</strong></td>
                  <td>
                    <div className="d-flex">
                      <strong className="h4">ค่าจ้างบรรทุกคนเฉลี่ย</strong>
                    </div>
                  </td>
                  <td className="text-right"><strong className="h2 font-primary">10</strong></td>
                  <td className="text-right"> บาท/เที่ยว</td>
                </tr>

                <tr>
                  <td><strong className="h4 text-muted">03</strong></td>
                  <td>
                    <div className="d-flex">
                      <strong className="h4">ค่าบริการอื่นเฉลี่ย</strong>
                    </div>
                  </td>
                  <td className="text-right"><strong className="h2 font-primary">0</strong></td>
                  <td className="text-right"> บาท/เที่ยว</td>
                </tr> */}

                <tr>
                  <td><strong className="h4 text-muted">02</strong></td>
                  <td>
                    <div className="d-flex">
                      <strong className="h4">รายได้ผู้ขับรถเฉลี่ย</strong>
                    </div>
                  </td>
                  <td className="text-right"><strong className="h2 font-primary">{stat.avgTotalAmountDriver.toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2
                  })}</strong></td>
                  <td className="text-right"> บาท/เที่ยว</td>
                </tr>

                <tr>
                  <td><strong className="h4 text-muted">03</strong></td>
                  <td>
                    <div className="d-flex">
                      <strong className="h4">ระยะทางเฉลี่ยต่อวัน</strong>
                    </div>
                  </td>
                  <td className="text-right"><strong className="h2 font-primary">{stat.avgDistanceDriver.toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2
                  })}</strong></td>
                  <td className="text-right"> กิโลเมตร/คัน/วัน</td>
                </tr>

                <tr>
                  <td><strong className="h4 text-muted">04</strong></td>
                  <td>
                    <div className="d-flex">
                      <strong className="h4">ระยะทางเฉลี่ย</strong>
                    </div>
                  </td>
                  <td className="text-right"><strong className="h2 font-primary">{stat.avgDistance.toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2
                  })}</strong></td>
                  <td className="text-right"> กิโลเมตร/เที่ยว</td>
                </tr>

                <tr>
                  <td><strong className="h4 text-muted">05</strong></td>
                  <td>
                    <div className="d-flex">
                      <strong className="h4">ระยะเวลาเดินทางเฉลี่ย</strong>
                    </div>
                  </td>
                  <td className="text-right"><strong className="h2 font-primary">{stat.avgDuration.toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2
                  })}</strong></td>
                  <td className="text-right"> นาที/เที่ยว</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}
export default ReportStats;
