import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'react-simple-snackbar'
import Meta from '../../interface/meta';
import { setAppLoading } from '../../redux/app.reducer';
import { useAppDispatch } from '../../redux/hooks';
import Breadcrumb from '../../component/breadcrumb';
import { filterOrdersApi } from '../../api/order';
import { IFilterOrder } from '../../interface/order';
import { getProfileUserByApi } from '../../api/user';
import { IUser } from '../../interface/user';
import Pagination from '../../component/pagination';
import moment from 'moment';
import Datatable from '../../component/datatable';
import { createRoot } from 'react-dom/client';

const UserHistory = () => {
  let { id } = useParams();
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<IFilterOrder[]>([]);
  const [user, setUser] = useState<IUser>();
  const [meta, setMeta] = useState<Meta>({
    page: 0,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });

  const getProfileUserById = async () => {
    try {
      const { data } = await getProfileUserByApi(id!);
      setUser(data)
    } catch (e: any) {
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  const filterHistoryOrders = async (page: number, limit: number) => {
    try {
      setLoading(true);
      const { data } = await filterOrdersApi({
        customerId: id,
        page,
        limit,
      });

      setOrders(data.results ?? [])

      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
      navigate(`${process.env.PUBLIC_URL}/users`)
    }
  }

  useEffect(() => {
    getProfileUserById();
    filterHistoryOrders(1, 10);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  return (
    <>
      <Breadcrumb
        title={"สมาชิก"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "สมาชิก", link: "/users" },
          { title: "ประวัติการซื้อ" },
        ]}
      />

      <div className="card">
        <div className="card-body">
          <div className='mb-2'>
            <h4 className="mt-2">{user?.firstName} {user?.lastName}</h4>
            <h6 className="text-muted">ID: {user?.userNo}</h6>
          </div>
          <div>
            <Datatable
              columns={[
                {
                  title: "Order No.",
                  width: 80,
                  data: "orderNo",
                  createdCell(cell, cellData, rowData: IFilterOrder, rowIndex, colIndex) {
                    createRoot(cell).render(
                      <div className='hover-underline cursor-pointer' onClick={() => navigate(`${process.env.PUBLIC_URL}/orders/${rowData.id}`)}>{rowData.orderNo}</div>
                    )
                  },
                },
                {
                  title: "เวลา",
                  width: 150,
                  data: "createdAt",
                  render(data: string, type, row, meta) {
                    return moment(data).format("DD/MM/yyyy HH:mm:ss")
                  },
                },
                {
                  title: "ค่าโดยสาร",
                  data: "totalAmount",
                  width: 80,
                  render(data: number, type, row, meta) {
                    return `${data}`
                  },
                },
                {
                  title: "ระยะทาง",
                  data: "distance",
                  width: 80,
                  render(data: number, type, row, meta) {
                    return `~ ${data.toFixed(2)}km`
                  },
                },
                {
                  title: "สถานนะ",
                  data: "status",
                  width: 80,
                  render(data: number, type, row, meta) {
                    switch (data) {
                      case 0:
                        return "ยกเลิก"
                      case 1:
                        return "ออเดอร์ใหม่"
                      case 2:
                        return "รับงาน"
                      case 3:
                        return "รับผู้โดยสาร"
                      case 4:
                        return "มอบหมาย"
                      case 5:
                        return "ส่งสำเร็จ"
                      default:
                        return ""
                    }
                  },
                },
                {
                  title: "ชำระ",
                  width: 80,
                  data: "paymentMethod"
                },
              ]}
              data={orders}
            />

            <Pagination
              active={meta.page}
              limit={meta.limit}
              onPageChange={(page) => {
                filterHistoryOrders(page, meta.limit);
              }}
              pageRangeDisplayed={5}
              total={meta.totalCount}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default UserHistory;
