import { AxiosResponse } from "axios";
import { IOrder, IOrderLogs, IOrderReportStatResponse, IOrderWithPagignation } from "../interface/order";
import { http } from "../utils/http";

export const filterOrdersApi = async (payload: {
  orderNo?: string;
  customerId?: string;
  driverId?: string;
  licensePlate?: string;
  status?: string;
  begin?: number;
  end?: number;
  page: number;
  limit: number;
}): Promise<AxiosResponse<IOrderWithPagignation>> => {
  return await http.get("/v1/admin/orders/filter", payload)
};

export const filterOrderSummaryStatusApi = async (payload: {
  begin?: number;
  end?: number;
}): Promise<AxiosResponse<object>> => {
  return await http.get("/v1/admin/orders/filter/summary-status", payload)
};

export const getOrderByIdApi = async (id: string): Promise<AxiosResponse<IOrder>> => {
  return await http.get(`/v1/admin/orders/${id}`)
};

export const deleteOrderByIdApi = async (id: string): Promise<AxiosResponse<void>> => {
  return await http.del(`/v1/admin/orders/${id}`)
};

export const getOrderLogsByOrderIdApi = async (orderId: string): Promise<AxiosResponse<IOrderLogs[]>> => {
  return await http.get(`/v1/admin/order-logs/${orderId}`)
};

export const filterOrderReportStatApi = async (begin: number, end: number, type?: string): Promise<AxiosResponse<IOrderReportStatResponse>> => {
  return await http.get(`/v1/admin/orders/report/stat`, {
    type,
    begin,
    end,
  })
};

export const filterOrderReportCarPerDayApi = async (begin: number, end: number, type?: string): Promise<AxiosResponse<any>> => {
  return await http.get(`/v1/admin/orders/report/car-per-day`, {
    type,
    begin,
    end,
  })
};

export const filterOrderReportTripsPerDayApi = async (begin: number, end: number, type?: string): Promise<AxiosResponse<any>> => {
  return await http.get(`/v1/admin/orders/report/trips-per-day`, {
    type,
    begin,
    end,
  })
};

export const getOrderSummaryDashboardApi = async (): Promise<AxiosResponse<any>> => {
  return await http.get(`/v1/admin/orders/summary-dashboard`)
};

export const assignDriverToOrderApi = async (orderId: string, driverId: string): Promise<void> => {
  return await http.post(`/v1/admin/orders/assign-driver`, {
    orderId,
    driverId,
  })
}