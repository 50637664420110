import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'react-simple-snackbar'
import _ from 'lodash';
import { deleteUsersApi, filterUsersApi, updateUsersStatusApi } from '../../api/user';
import Datatable, { DatatableElement } from '../../component/datatable';
import Meta from '../../interface/meta';
import { IUser } from '../../interface/user';
import { setAppLoading } from '../../redux/app.reducer';
import { useAppDispatch } from '../../redux/hooks';
import { createRoot } from 'react-dom/client';
import Pagination from '../../component/pagination';
import Breadcrumb from '../../component/breadcrumb';
import ModalViewImage from '../../component/modal-view-image';
import { getSrcImage } from '../../utils/utils';

const UserList = () => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [showImage, setShowImage] = useState({ show: false, src: "" });
  const keyword = useRef<HTMLInputElement>(null);
  const status = useRef<HTMLSelectElement>(null);
  const table = useRef<DatatableElement>(null);
  const action = useRef<HTMLSelectElement>(null);
  const [meta, setMeta] = useState<Meta>({
    page: 0,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });

  const filterUsers = async (page: number, limit: number) => {
    try {
      setLoading(true);
      const { data } = await filterUsersApi({
        role: "user",
        keyword: keyword.current?.value,
        status: status.current?.value ?? "0,1",
        page,
        limit,
      });

      setUsers(data.results ?? [])
      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  const updateStatusUsers = async () => {
    try {
      if (action.current?.value === "") return

      const selected: IUser[] = table.current?.selected();
      if (selected.length === 0) return
      setLoading(true);
      const uid = selected.map(e => e.id);
      const status = +action.current!.value;
      if (status === -1) {
        await deleteUsersApi({ uid });
      } else {
        await updateUsersStatusApi({ uid, status });
      }
      filterUsers(meta.page, meta.limit);
      openSnackbar("Successfuly");

      setLoading(false);
    } catch (error: any) {
      console.log(error);

      setLoading(false);
      let msg: string | undefined;
      if (typeof error?.response?.data?.message === "string") {
        msg = error?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  useEffect(() => {
    filterUsers(1, 10);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  let debounce: any = undefined;
  return (
    <>
      <Breadcrumb
        title={"สมาชิก"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "สมาชิก" }
        ]}
      />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center flex-row mb-2">
                <h3 className="mt-4 ml-1">ทั้งหมด {meta.totalCount.toLocaleString('en-US')} รายการ</h3>
                <div className='d-flex flex-row align-items-end' style={{ gap: "1rem" }}>
                  <div>
                    <span className="text-muted tx-13">ค้นหา</span>
                    <input
                      type="text"
                      className="form-control global_filter"
                      ref={keyword}
                      placeholder="ID / ชื่อ"
                      onChange={() => {
                        if (!!debounce) {
                          debounce.cancel()
                        }
                        debounce = _.debounce(() => {
                          filterUsers(1, meta.limit)
                        }, 500);
                        debounce();
                      }}
                    />
                  </div>

                  <div style={{ width: 150 }}>
                    <span className="text-muted tx-13">สถานนะ</span>
                    <select className="form-control" ref={status} onChange={() => {
                      filterUsers(1, meta.limit)
                    }}>
                      <option value="0,1">ทั้งหมด</option>
                      <option value="1">ใช้งาน</option>
                      <option value="0">ระงับ</option>
                    </select>
                  </div>

                  <div style={{ width: 150 }}>
                    <span className="text-muted tx-13">ดำเนินการ</span>
                    <select className="form-control" ref={action}>
                      <option value="">เลือก</option>
                      <option value="1">ใช้งาน</option>
                      <option value="0">ระงับ</option>
                      <option value="-1">ลบ</option>
                    </select>
                  </div>

                  <button onClick={() => updateStatusUsers()} type="button" className="btn btn-primary">ดำเนินการ</button>
                </div>

              </div>
              <Datatable
                ref={table}
                data={users}
                columns={[
                  {
                    isCheckbox: true,
                    title: "",
                    data: function (row, type, set) {
                      return '';
                    }
                  },
                  {
                    title: "รูป",
                    width: "80px",
                    data: null,
                    createdCell(cell, cellData, rowData: IUser) {
                      const cb = (src) => {
                        setShowImage({
                          src: src,
                          show: true,
                        })
                      }
                      createRoot(cell).render(
                        <div className="cursor-pointer img-thumbmail" onClick={() => {
                          cb(getSrcImage(rowData.picture))
                        }}>
                          <img className='lazy img-responsive' alt="" src={getSrcImage(rowData.picture)} />
                        </div>
                      )
                    },
                  },
                  {
                    title: "ID",
                    width: 100,
                    data: "userNo",
                  },
                  {
                    title: "ชื่อ-นามสกุล",
                    width: 200,
                    render: (_1, _2, row: IUser) => {
                      return `${row.firstName} ${row.lastName}`
                    },
                  },
                  {
                    title: "อีเมล",
                    width: 120,
                    data: "email"
                  },
                  {
                    title: "เบอร์โทร",
                    width: 120,
                    data: "phoneNumber"
                  },
                  {
                    title: "ประวัติการสั่งซื้อ",
                    width: 100,
                    data: null,
                    createdCell(cell, cellData, rowData: IUser, rowIndex, colIndex) {
                      createRoot(cell).render(
                        <button onClick={() => navigate(`${process.env.PUBLIC_URL}/users/history/${rowData.id}`)} className="btn btn-sm btn-outline-primary mb-1">ประวัติ</button>
                      )
                    },
                  },
                  {
                    title: "แต้ม",
                    width: 100,
                    data: "point"
                  },
                  {
                    title: "สถานนะ",
                    width: 100,
                    data: "status",
                    render: (data: number) => {
                      switch (data) {
                        case 0:
                          return "<span class='badge badge-warning'>ระงับ</span>"
                        case 1:
                          return "<span class='badge badge-success'>ใช้งาน</span>"
                        case 2:
                          return "<span class='badge badge-danger'>ไม่อนุมติ</span>"
                        case 3:
                          return "<span class='badge badge-primary'>รออนุมติ</span>"
                        default:
                          return ""
                      }
                    },
                  },
                ]}
                actions={[
                  {
                    button: {
                      callback(data: IUser) {
                        navigate(`${process.env.PUBLIC_URL}/users/edit/${data.id}`)
                      },
                      code: <button className="btn btn-outline-primary btn-sm mb-1">แก้ไข</button>
                    },
                  },
                ]}
              />

              <Pagination
                active={meta.page}
                limit={meta.limit}
                onPageChange={(page) => {
                  filterUsers(page, meta.limit);
                }}
                pageRangeDisplayed={5}
                total={meta.totalCount}
              />
            </div>
          </div>
        </div>
      </div>
      <ModalViewImage
        open={showImage.show}
        src={showImage.src}
        toggle={() => {
          setShowImage(prev => ({
            ...prev,
            show: false,
          }))
        }}
      />
    </>
  )
}

export default UserList;
