import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GoogleMap, useJsApiLoader, Polyline, Marker, LoadScriptProps } from '@react-google-maps/api';
import { assignDriverToOrderApi, deleteOrderByIdApi, getOrderByIdApi, getOrderLogsByOrderIdApi } from "../../api/order";
import Breadcrumb from "../../component/breadcrumb";
import { IOrder, IOrderLogs } from "../../interface/order";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppConfirm, useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";
import { getSrcImage } from "../../utils/utils";
import { IReview } from "../../interface/review";
import { getReviewByOrderIdApi } from "../../api/review";
import { moneyDisplay } from "../../utils/money";
import { Modal, ModalBody } from "reactstrap";
import { AsyncPaginate } from "react-select-async-paginate";
import { filterDriversApi } from "../../api/user";

const libraries: LoadScriptProps['libraries'] = ["geometry"];

const OrderDetail: FC = () => {
  const { id } = useParams();
  let libRef = React.useRef(libraries)

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDOxhEvi4hQO5fCdY7ca6SBMDviysSGhsc",
    libraries: libRef.current,
  })

  const [order, setOrder] = useState<IOrder>();
  const [orderLogs, setOrderLogs] = useState<IOrderLogs[]>([]);
  const [review, setReview] = useState<IReview | null>(null);
  const [assignDriver, setAssignDriver] = useState<{ value: string, label: string }>();
  const [assignModal, setAssignModal] = useState({
    show: false
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [loading, setLoading] = useState(false);
  const [stepMap, setStepMap] = useState<google.maps.LatLng[]>([]);
  const orderStatus = ["ยกเลิก", "ออเดอร์ใหม่", "รับงาน", "รับผู้โดยสาร", "มอบหมาย", "สำเร็จ"]
  const { confirm } = useAppConfirm();

  const getOrderById = async () => {
    try {
      setLoading(true);

      const { data } = await getOrderByIdApi(id!);

      const [logs, review] = await Promise.all([
        getOrderLogsByOrderIdApi(data.id),
        getReviewByOrderIdApi(data.id),
      ]);

      setOrderLogs(logs.data)
      setReview(review.data)
      setOrder(data)
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
      navigate(`${process.env.PUBLIC_URL}/orders`)
    }
  }

  const deleteOrderById = async () => {
    try {
      const isConfirmed = await confirm({
        title: "ยืนยันการทำรายการ",
        description: "",
        textCancel: "ยกเลิก",
        classBtnCancel: "btn btn-light",
        textConfirm: "ยืนยัน",
        classBtnConfirm: "btn btn-danger",
      });

      if (!isConfirmed) return;

      setLoading(true);

      await deleteOrderByIdApi(id!);
      const { data } = await getOrderByIdApi(id!);

      const [logs] = await Promise.all([
        getOrderLogsByOrderIdApi(data.id),
      ]);
      setOrderLogs(logs.data)
      setOrder(data)
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
      navigate(`${process.env.PUBLIC_URL}/orders`)
    }
  }

  const filterOnlineUser = async (search, loadedOptions, additional) => {
    try {
      const { data } = await filterDriversApi({
        isOnline: true,
        keyword: search ?? "",
        status: "1",
        vehicleType: order?.service.id,
        lat: order?.startLocation.lat,
        lng: order?.startLocation.lng,
        page: additional?.page ?? 1,
        limit: 10,
      });

      return ({
        options: data.results?.map(e => ({ value: e.id, label: `${e.userNo}: ${e.firstName} ${e.lastName} (${e.distance} km)` })) ?? [],
        hasMore: data.meta.hasMore,
        additional: {
          page: additional.page + 1,
        },
      });
    } catch (error) {
      return ({
        options: [],
        hasMore: false,
        additional: {
          page: additional.page + 1,
        },
      });
    }
  }

  const assignDriverToOrder = async () => {
    try {

      setLoading(true);
      await assignDriverToOrderApi(id!, assignDriver?.value ?? "");
      setAssignModal({ show: false });
      setAssignDriver(undefined);
      const [order, logs] = await Promise.all([
        getOrderByIdApi(id!),
        getOrderLogsByOrderIdApi(id!),
      ]);
      setOrderLogs(logs.data);
      setOrder(order.data);
      setLoading(false);
      openSnackbar("Assign Completed");
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    getOrderById();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!isLoaded) return
    if (!order) return
    let poly: google.maps.LatLng[] = []
    for (const polyline of order.polyline) {

      const decode = google.maps?.geometry?.encoding.decodePath(polyline);
      for (const latlng of decode) {
        poly.push(latlng)
      }
    }
    setStepMap(poly)

  }, [order, isLoaded])
  return (
    <>
      <Breadcrumb
        title={`Order No. ${order?.orderNo ?? ""}`}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "เรียกรถ", link: "/orders" },
          { title: `Order No. ${order?.orderNo ?? ""}` }
        ]}
      />

      <div className="row mb-2">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg">
                  <div className="row">
                    <div className="col-auto">
                      <div className="thumbnail">
                        <img alt="" className="lazy img-responsive" src={getSrcImage(order?.customer?.picture)} />
                      </div>
                    </div>
                    <div className="col-lg">
                      <span className="text-muted tx-12">ผู้ใช้บริการ</span>
                      <br />
                      <Link to={`/users/edit/${order?.customer.id}`}>{order?.customer.contactName}</Link>
                      <br />
                      <span className="tx-12">เบอร์โทร {order?.customer.phoneNumber}</span>
                    </div>
                  </div>
                </div>

                <div className="col-lg">
                  <span className="text-muted tx-12">ออเดอร์วันที่</span><br />
                  <span>{moment(order?.createdAt).format("DD/MM/yyyy HH:mm:ss")}</span>

                </div>

                <div className="col-lg">
                  <span className="text-muted tx-12">คะแนนความพึงพอใจ</span><br />
                  <span className="h2 font-weight-bold">{!!review ? `${review?.rate}/5` : "N/A"}</span><br />
                </div>

                <div className="col-lg">
                  <span className="text-muted tx-12">หมายเหตุ</span><br />
                  <span className="tx-13">{!!review ? review.detail : "N/A"}</span>
                </div>

                <div className="col-auto">
                  <span className="text-muted tx-12">สถานะ</span><br />
                  <span className="h5 text-primary">{orderStatus[order?.status || 0]}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-2">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row mb-4">
                <div className="container_timeline">
                  <div className="timeline-block timeline-block-right mb-2">
                    <div className="marker active" style={{ zIndex: 1 }}><i className="fa fa-map-marker " aria-hidden="true"></i></div>
                    <div className="timeline-content border-bottom pb-2">
                      <div className="row">
                        <div className="col-12">
                          <div className="row">
                            <div className="col">
                              <span className="text-muted tx-12">ต้นทาง</span>
                              <br />
                              <span>{order?.startLocation.address}</span>
                              <br />
                              <span className="text-muted tx-13">หมายเหตุ: {order?.startLocation.detail}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="timeline-block timeline-block-right ">
                    <div className="marker active" style={{ zIndex: 1 }}><i className="fa fa-map-marker " aria-hidden="true"></i></div>
                    <div className="timeline-content ">
                      <div className="row">
                        <div className="col-12">
                          <div className="row">
                            <div className="col">
                              <span className="text-muted tx-12">ปลายทาง</span>
                              <br />
                              <span>{order?.endLocation.address}</span>
                              <br />
                              <span className="text-muted tx-13">หมายเหตุ: {order?.endLocation.detail}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-12 w-100" style={{ height: 500 }}>
                  {
                    loadError && (
                      <div style={{ height: "inherit", background: "#f0f2f5" }} className="align-items-center d-flex justify-content-center text-center">Map cannot be loaded right now, sorry.</div>
                    )
                  }

                  {
                    isLoaded ? (
                      <GoogleMap
                        id='maps'
                        mapContainerStyle={{
                          height: 500,
                          width: "100%"
                        }}
                        center={{
                          lat: order?.startLocation.lat ?? 0,
                          lng: order?.startLocation.lng ?? 0,
                        }}

                        zoom={15}
                      >
                        <Polyline
                          path={stepMap}
                          options={{
                            strokeColor: '#FF0000',
                            strokeOpacity: 1,
                            strokeWeight: 3,
                            clickable: false,
                            draggable: false,
                            editable: false,
                            visible: true,
                            zIndex: 1,
                          }}
                        />
                        <Marker
                          position={{ lat: order?.startLocation.lat || 0, lng: order?.startLocation.lng || 0 }}
                        />
                        <Marker
                          position={{ lat: order?.endLocation.lat || 0, lng: order?.endLocation.lng || 0 }}
                        />
                      </GoogleMap>
                    ) : <></>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="row mb-2">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-12 mb-2">
                  <h6 className="text-muted font-weight-bold">ข้อมูลผู้ขับ</h6>
                </div>
                <div className="ml-2" style={{ width: 80 }}>
                  <div className="thumbnail">
                    <img alt="" className="lazy img-responsive" src={getSrcImage(order?.driver?.picture)} />
                  </div>
                </div>
                <div className="col-lg">
                  <span className="text-muted tx-12">ชื่อนามสกุล</span><br />
                  <span className="h5 font-weight-bold">
                    {!!order?.driver ? <Link to={`/drivers/${order?.driver.id}`}>{order?.driver?.firstName} {order?.driver?.lastName}</Link> : "N/A"}
                  </span>
                  <br />
                  <span className="tx-10 text-muted">รหัสพนักงาน {!!order?.driver ? `${order?.driver?.userNo}` : "N/A"}</span>
                </div>
                <div className="col-lg">
                  <span className="text-muted tx-12">เบอร์โทรศัพท์</span><br />
                  <span className="h5 font-weight-bold">{!!order?.driver ? `${order?.driver?.phoneNumber}` : "N/A"}</span>
                </div>
                <div className="col-lg">
                  <span className="text-muted tx-12">หมายเลขใบอนุญาติขับรถ</span><br />
                  <span className="h5 font-weight-bold">{!!order?.driver ? `${order?.driver?.drivingLicense}` : "N/A"}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-2">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12 mb-2">
                  <h6 className="text-muted font-weight-bold">ข้อมูลรถ</h6>
                </div>
                <div className="ml-2" style={{ width: 80 }}>
                  <div className="thumbnail">
                    <img alt="" className="lazy img-responsive" src={getSrcImage(order?.driver?.picture)} />
                  </div>
                </div>

                <div className="col-lg">
                  <span className="text-muted tx-12">รายละเอียด</span><br />
                  <span className="h5 font-weight-bold mr-2">{!!order?.driver ? `${order?.driver?.vehicleMake}` : "N/A"}</span>
                  <span className="h5 font-weight-bold">{!!order?.driver ? `${order?.driver?.vehicleColor}` : "N/A"}</span>
                </div>

                <div className="col-lg">
                  <span className="text-muted tx-12">หมายเลขทะเบียน</span>
                  <br />
                  <span className="h5 font-weight-bold">{!!order?.driver ? `${order?.driver.licensePlate}` : "N/A"}</span>
                </div>

                <div className="col-lg">
                  <span className="text-muted tx-12">ประเภทรถ</span><br />
                  <span className="h5 font-weight-bold">{!!order?.driver ? `${order?.service.name}` : "N/A"}</span>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="row mb-2">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12 mb-2">
                  <h6 className="text-muted font-weight-bold">สถานะการจัดส่ง</h6>
                </div>
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th>สถานะ</th>
                    <th>ละติจูด,ลองจิจูด</th>
                    <th>วันที่</th>
                    <th>เวลา</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    orderLogs.map((logs, index) => (
                      <tr key={`logs_${index}`}>
                        <td>{logs.remark}</td>
                        <td>{logs.lat},{logs.lng}</td>
                        <td>{moment(logs.createdAt).format("DD/MM/yyyy")}</td>
                        <td>{moment(logs.createdAt).format("HH:mm:ss")}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


      <div className="row mb-2">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12 mb-2">
                  <h6 className="text-muted font-weight-bold">ค่าโดยสาร</h6>
                </div>
              </div>
              <table className="table">
                <tbody>
                  <tr>
                    <td>ประเภทรถ</td>
                    <td className="text-right"><span className="font-weight-bold">{order?.service?.name}</span></td>
                  </tr>
                  <tr>
                    <td>ระยะทาง</td>
                    <td className="text-right"><span className="font-weight-bold">~ {order?.distance.toFixed(2)} km</span></td>
                  </tr>

                  <tr>
                    <td>ระยะเวลา</td>
                    <td className="text-right"><span className="font-weight-bold">~ {((order?.duration ?? 0) / 60).toFixed(2)} นาที</span></td>
                  </tr>

                  <tr>
                    <td>
                      <span className="tx-13 text-muted">ค่าจ้างระยะทาง 2 km แรก</span><br />
                      <span className="tx-13 text-muted">ค่าจ้างตามระยะทาง</span><br />
                      <span className="tx-13 text-muted">ส่วนลด</span><br />
                      <span className="font-weight-bold">ค่าโดยกสารรวม</span>
                    </td>
                    <td className="text-right">
                      <span className="font-weight-bold">
                        <span className="tx-13 text-muted">{moneyDisplay(order?.startPriceAmount)}</span><br />
                        <span className="tx-13 text-muted">{moneyDisplay(order?.chargePerKilometerAmount)}</span><br />
                        <span className="tx-13 text-muted">{moneyDisplay(order?.discount)}{!!order?.promotionCode ? ` (${order?.promotionCode})` : ""}</span><br />
                        <span className="font-weight-bold">{moneyDisplay(order?.totalAmount)}</span>
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>ค่าธรรมเนียมการใช้ระบบ</td>
                    <td className="text-right"><span className="font-weight-bold">{moneyDisplay(order?.commissionAmount)}</span></td>
                  </tr>

                  <tr>
                    <td>ชำระเงิน</td>
                    <td className="text-right"><span className="font-weight-bold">{order?.paymentMethod}</span></td>
                  </tr>

                </tbody>
              </table>
              <div className="row text-right">
                <div className="col-12 border-top pt-2 pb-2"><span className="h4 font-weight-bold">ทั้งหมด {moneyDisplay(order?.totalAmount)}</span></div>
              </div>
            </div>
            <div className="row p-4">
              {
                [1, 2, 3, 4].includes(order?.status ?? -1) && (
                  <button className="btn btn-outline-warning ml-auto" onClick={() => {
                    deleteOrderById();
                  }}>ยกเลิกออเดอร์</button>
                )
              }

              {
                [1, 4].includes(order?.status ?? -1) && (
                  <button className="btn btn-outline-primary ml-2" onClick={() => {
                    setAssignModal({
                      show: true,
                    })
                  }}>จัดส่ง/มอบหมาย</button>
                )
              }
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={assignModal.show} toggle={() => {
        setAssignModal({
          show: false,
        })
        setAssignDriver(undefined)
      }} centered={false}>
        <div className="modal-title modal-header">
          <h5 className="modal-title">มอบหมายงาน</h5>
          <button type="button" onClick={() => {
            setAssignModal({
              show: false,
            })
            setAssignDriver(undefined)
          }} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          <form onSubmit={(e) => {
            e.preventDefault();
            assignDriverToOrder();
          }}>

            <div className="form-group">
              <div className="col-12">
                <label>คำสั่งซื้อ Order No.</label>
              </div>
              <div className="col-12">
                <input
                  className="form-control"
                  value={order?.orderNo ?? ""}
                  disabled={true}
                />
              </div>
            </div>

            <div className="form-group">
              <div className="col-12">
                <label>ประเภทรถ</label>
              </div>
              <div className="col-12">
                <input
                  className="form-control"
                  value={order?.service?.name ?? ""}
                  disabled={true}
                />
              </div>
            </div>

            <div className="form-group">
              <div className="col-12">
                <label>พนักงานขับ</label>
              </div>
              <div className="col-12">
                <AsyncPaginate
                  debounceTimeout={500}
                  value={assignDriver}
                  additional={{
                    page: 1,
                  }}
                  styles={{
                    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                      return {
                        ...styles,
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 3,
                        textOverflow: "ellipsis",
                      }
                    }
                  }}
                  loadOptions={filterOnlineUser}
                  onChange={(e: any) => {
                    setAssignDriver(e)
                  }}
                />
              </div>
            </div>

            <div className="form-group text-right">
              <button
                disabled={loading}
                type='button'
                className='btn btn-outline-warning mr-2'
                onClick={() => {
                  setAssignModal({
                    show: false,
                  })
                  setAssignDriver(undefined)
                }}
              >ยกเลิก</button>
              <button
                disabled={loading || !assignDriver || assignDriver?.value === ""}
                type='submit'
                className='btn btn-primary mr-2'

              >บันทึก</button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default OrderDetail;