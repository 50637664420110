import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'react-simple-snackbar'
import _ from 'lodash';
import { deleteUsersApi, filterUsersApi, updateUsersStatusApi } from '../../api/user';
import Datatable, { DatatableElement } from '../../component/datatable';
import Meta from '../../interface/meta';
import { IUser } from '../../interface/user';
import { selectApp, setAppLoading } from '../../redux/app.reducer';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { createRoot } from 'react-dom/client';
import Pagination from '../../component/pagination';
import ModalViewImage from '../../component/modal-view-image';
import { getSrcImage } from '../../utils/utils';
import moment from 'moment';
import Breadcrumb from '../../component/breadcrumb';
import { Modal, ModalBody } from 'reactstrap';
import { compensationByUserIdApi } from '../../api/wallet';
import { moneyDisplay } from '../../utils/money';

const DriverList = () => {
  const dispatch = useAppDispatch();
  const { badge } = useAppSelector(selectApp)
  let navigate = useNavigate();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [showImage, setShowImage] = useState({ show: false, src: "" });
  const [compensatinModal, setCompensationModal] = useState({
    show: false,
    user: {} as IUser,
    amount: 1,
    remark: "",
  });
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const keyword = useRef<HTMLInputElement>(null);
  const status = useRef<HTMLSelectElement>(null);
  const table = useRef<DatatableElement>(null);
  const action = useRef<HTMLSelectElement>(null);

  const [meta, setMeta] = useState<Meta>({
    page: 0,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });

  const filterUsers = async (page: number, limit: number) => {
    try {
      setLoading(true);
      const { data } = await filterUsersApi({
        role: "driver",
        keyword: keyword.current?.value,
        status: status.current?.value ?? "0,1,2,3",
        page,
        limit,
      });

      setUsers(data.results ?? [])
      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  const updateStatusUsers = async () => {
    try {
      if (action.current?.value === "") return

      const selected: IUser[] = table.current?.selected();
      if (selected.length === 0) return
      setLoading(true);
      const uid = selected.map(e => e.id);
      const status = +action.current!.value;
      if (status === -1) {
        await deleteUsersApi({ uid });
      } else {
        await updateUsersStatusApi({ uid, status });
      }
      filterUsers(meta.page, meta.limit);
      openSnackbar("Successfuly");

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof error?.response?.data?.message === "string") {
        msg = error?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  const compensationByUserId = async (userId: string, amount: number, remark: string) => {
    try {
      setLoading(true);
      await compensationByUserIdApi({ userId, amount, remark });
      setCompensationModal({
        show: false,
        user: {} as IUser,
        amount: 1,
        remark: "",
      })
      filterUsers(meta.page, meta.limit);
      openSnackbar("Successfuly");
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof error?.response?.data?.message === "string") {
        msg = error?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  useEffect(() => {
    filterUsers(1, 10);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  let debounce: any = undefined;
  return (
    <>
      <Breadcrumb
        title={"พนักงานขับรถ"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "พนักงานขับรถ" }
        ]}
      />

      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item">
              <Link className={`nav-link active`} to={`${process.env.PUBLIC_URL}/drivers`}>พนักงานทั้งหมด</Link>
            </li>

            <li className="nav-item">
              <Link className={`nav-link`} to={`${process.env.PUBLIC_URL}/drivers/review`}>
                สมัครใหม่
                {
                  badge.newDriver > 0 && (
                    <span className="badge badge-pill badge-danger ml-1">{badge.newDriver}</span>
                  )
                }
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center flex-row mb-2">
                <h3 className="mt-4 ml-1">ทั้งหมด {meta.totalCount.toLocaleString('en-US')} รายการ</h3>
                <div className='d-flex flex-row align-items-end' style={{ gap: "1rem" }}>
                  <div>
                    <span className="text-muted tx-13">ค้นหา</span>
                    <input
                      type="text"
                      className="form-control global_filter"
                      ref={keyword}
                      placeholder="ID / ชื่อ"
                      onChange={() => {
                        if (!!debounce) {
                          debounce.cancel()
                        }
                        debounce = _.debounce(() => {
                          filterUsers(1, meta.limit)
                        }, 500);
                        debounce();
                      }}
                    />
                  </div>

                  <div style={{ width: 150 }}>
                    <span className="text-muted tx-13">สถานนะ</span>
                    <select className="form-control" ref={status} onChange={() => {
                      filterUsers(1, meta.limit)
                    }}>
                      <option value="0,1,2,3">ทั้งหมด</option>
                      <option value="1">ใช้งาน</option>
                      <option value="3">รออนุมัติ</option>
                      <option value="2">ไม่อนุมัติ</option>
                      <option value="0">ระงับ</option>
                    </select>
                  </div>

                  <div style={{ width: 150 }}>
                    <span className="text-muted tx-13">ดำเนินการ</span>
                    <select className="form-control" ref={action}>
                      <option value="">เลือก</option>
                      <option value="1">ใช้งาน</option>
                      <option value="0">ระงับ</option>
                      <option value="-1">ลบ</option>
                    </select>
                  </div>

                  <button onClick={() => updateStatusUsers()} type="button" className="btn btn-primary">ดำเนินการ</button>
                </div>

              </div>

              <Datatable
                ref={table}
                data={users}
                columns={[
                  {
                    isCheckbox: true,
                    title: "",
                    data: function (row, type, set) {
                      return '';
                    }
                  },
                  {
                    title: "รูป",
                    width: "80px",
                    data: null,
                    createdCell(cell, cellData, rowData: IUser) {
                      const cb = (src) => {
                        setShowImage({
                          src: src,
                          show: true,
                        })
                      }
                      createRoot(cell).render(
                        <div className="cursor-pointer img-thumbmail" onClick={() => {
                          cb(getSrcImage(rowData.picture))
                        }}>
                          <img className='lazy img-responsive' alt="" src={getSrcImage(rowData.picture)} />
                        </div>
                      )
                    },
                  },
                  {
                    title: "ID",
                    width: "100px",
                    data: "userNo",
                    createdCell(cell, cellData, rowData: IUser, rowIndex, colIndex) {
                      createRoot(cell).render(
                        <div className='hover-underline cursor-pointer' onClick={() => navigate(`${process.env.PUBLIC_URL}/drivers/${rowData.id}`)}>{rowData.userNo}</div>
                      )
                    },
                  },
                  {
                    title: "ชื่อ-นามสกุล",
                    width: "200px",
                    render: (_1, _2, row: IUser) => {
                      return `${row.firstName} ${row.lastName}`
                    },
                  },
                  {
                    title: "เลขที่บัตรประชาชน",
                    width: 120,
                    data: "identificationNumber"
                  },
                  {
                    title: "วันเกิด",
                    width: 120,
                    data: "birthDate",
                    render(data, type, row, meta) {
                      return `${data === "" ? "" : moment(data).format("yyyy-MM-DD")}`;
                    },
                  },
                  {
                    title: "เบอร์โทร",
                    width: 120,
                    data: "phoneNumber"
                  },
                  {
                    title: "อีเมล",
                    width: 120,
                    data: "email"
                  },
                  {
                    title: "เครดิต",
                    width: 120,
                    data: null,
                    createdCell(cell, cellData, rowData: IUser) {
                      const cb = (user) => {
                        setCompensationModal({
                          user,
                          show: true,
                          amount: 1,
                          remark: "",
                        })
                      }
                      createRoot(cell).render(
                        <>
                          <div className='text-success'>{moneyDisplay(rowData.credit)}</div>
                          <button onClick={() => cb(rowData)} className="btn btn-sm btn-outline-primary mb-1">เติมเครดิต</button>
                        </>
                      )
                    },
                  },
                  {
                    title: "ทะเบียน",
                    width: 120,
                    data: "licensePlate"
                  },
                  {
                    title: "รุ่น",
                    width: 120,
                    data: "vehicleMake"
                  },
                  {
                    title: "สี",
                    width: 120,
                    data: "vehicleColor"
                  },
                  {
                    title: "ใบขับขี่",
                    width: 50,
                    data: null,
                    createdCell(cell, cellData, rowData: IUser) {
                      const cb = (src) => {
                        setShowImage({
                          src: src,
                          show: true,
                        })
                      }
                      createRoot(cell).render(
                        <div style={{ width: 50 }} className="cursor-pointer rounded" onClick={() => {
                          cb(getSrcImage(rowData.drivingPicture))
                        }}>
                          <img className='lazy img-responsive' alt="" src={getSrcImage(rowData.drivingPicture)} />
                        </div>
                      )
                    },
                  },
                  {
                    title: "บัตรประชาชน",
                    width: 80,
                    data: null,
                    createdCell(cell, cellData, rowData: IUser) {
                      const cb = (src) => {
                        setShowImage({
                          src: src,
                          show: true,
                        })
                      }
                      createRoot(cell).render(
                        <div className="cursor-pointer rounded" onClick={() => {
                          cb(getSrcImage(rowData.identificationPicture))
                        }}>
                          <img className='lazy img-responsive' alt="" src={getSrcImage(rowData.identificationPicture)} />
                        </div>
                      )
                    },
                  },
                  {
                    title: "สถานนะ",
                    width: 100,
                    data: "status",
                    render: (data: number) => {
                      switch (data) {
                        case 0:
                          return "<span class='badge badge-warning'>ระงับ</span>"
                        case 1:
                          return "<span class='badge badge-success'>ใช้งาน</span>"
                        case 2:
                          return "<span class='badge badge-danger'>ไม่อนุมติ</span>"
                        case 3:
                          return "<span class='badge badge-primary'>รออนุมติ</span>"
                        default:
                          return ""
                      }
                    },
                  },
                  {
                    title: "ประวัติการบริการ",
                    width: 120,
                    data: null,
                    createdCell(cell, cellData, rowData: IUser, rowIndex, colIndex) {
                      createRoot(cell).render(
                        <button
                          onClick={() => {
                            navigate(`${process.env.PUBLIC_URL}/drivers/${rowData.id}/service-history`)
                          }}
                          className="btn btn-sm btn-outline-primary mb-1">เพิ่มเติม</button>
                      )
                    },
                  },
                  {
                    title: "ประวัติพนักงาน",
                    width: 120,
                    data: null,
                    createdCell(cell, cellData, rowData: IUser, rowIndex, colIndex) {
                      createRoot(cell).render(
                        <button
                          onClick={() => {
                            navigate(`${process.env.PUBLIC_URL}/drivers/${rowData.id}`)
                          }}
                          className="btn btn-sm btn-outline-primary mb-1">เพิ่มเติม</button>
                      )
                    },
                  },
                ]}
              />

              <Pagination
                active={meta.page}
                limit={meta.limit}
                onPageChange={(page) => {
                  filterUsers(page, meta.limit);
                }}
                pageRangeDisplayed={5}
                total={meta.totalCount}
              />
            </div>
          </div>
        </div>
      </div>
      <ModalViewImage
        open={showImage.show}
        src={showImage.src}
        toggle={() => {
          setShowImage(prev => ({
            ...prev,
            show: false,
          }))
        }}
      />

      <Modal isOpen={compensatinModal.show} toggle={() => {
        if (loading) return;
        setCompensationModal({
          show: false,
          user: {} as IUser,
          amount: 1,
          remark: "",
        })
      }} centered={false}>
        <div className="modal-title modal-header">
          <h5 className="modal-title">เติมเครดิต</h5>
          <button type="button" onClick={() => {
            if (loading) return;
            setCompensationModal({
              show: false,
              user: {} as IUser,
              amount: 1,
              remark: "",
            })
          }} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody className="text-center">
          <form onSubmit={(e) => {
            e.preventDefault();
            compensationByUserId(compensatinModal.user.id, compensatinModal.amount, compensatinModal.remark);
          }}>
            <div className="form-group">
              <div className='row align-items-center'>
                <div className='col-3 text-left'>รหัสพนักงาน</div>
                <div className='col-9'>
                  <input className="form-control" disabled defaultValue={`${compensatinModal.user.userNo}`} />
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className='row align-items-center'>
                <div className='col-3 text-left'>พนักงาน</div>
                <div className='col-9'>
                  <input className="form-control" disabled defaultValue={`${compensatinModal.user.firstName} ${compensatinModal.user.lastName}`} />
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className='row align-items-center'>
                <div className='col-3 text-left'>จำนวนเงิน</div>
                <div className='col-9'>
                  <input
                    required
                    className="form-control"
                    type="number"
                    step={1}
                    min={1}
                    value={compensatinModal.amount}
                    onChange={e => {
                      setCompensationModal(prev => ({
                        ...prev,
                        amount: +e.target.value,
                      }))
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className='row align-items-center'>
                <div className='col-3 text-left'>หมายเหตุ</div>
                <div className='col-9'>
                  <input
                    className="form-control"
                    value={compensatinModal.remark}
                    onChange={e => {
                      setCompensationModal(prev => ({
                        ...prev,
                        remark: e.target.value,
                      }))
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="form-group text-right">
              <button disabled={loading} type='button' className='btn btn-outline-warning mr-2'>ยกเลิก</button>
              <button disabled={loading} type='submit' className='btn btn-primary mr-2'>บันทึก</button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default DriverList;
