import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from "react-router-dom";
import SnackbarProvider from 'react-simple-snackbar'

import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import AppLayout from './layout/app';
import Page from './layout/page';
import { useAppDispatch } from './redux/hooks';
import { setProfile, setRules } from './redux/authentication.reducer';
import { fetchProfileUserApi } from './api/user';
import { InitialLoading } from './layout/loader';
import './index.css';
import './index.scss';
import 'moment/locale/th';
import ConfirmDialog from './component/confirm';
import { getRuleByUserIdApi } from './api/core';
import { IRule } from './interface/rule';
import "./firebase";

const Root = () => {
  const dispatch = useAppDispatch();
  const [state, setState] = React.useState({
    isLoggedIn: false,
    isLoading: true,
  });

  const getRuleByUserId = async (id: string): Promise<IRule> => {
    try {
      const { data } = await getRuleByUserIdApi(id);
      return data;
    } catch (e: any) {
      throw e;
    }
  }

  const fetchAuth = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      let isLoggedIn = false;
      if (accessToken) {
        const { data } = await fetchProfileUserApi();
        const rules = await getRuleByUserId(data.id)
        dispatch(setRules(rules));
        dispatch(setProfile(data));
        isLoggedIn = true;
      }

      setState((prev) => ({
        ...prev,
        isLoggedIn: isLoggedIn,
        isLoading: false,
      }));
    } catch (e) {
      setState((prev) => ({
        ...prev,
        isLoggedIn: false,
        isLoading: false,
      }));
    }
  };

  useEffect(() => {
    fetchAuth();
    // eslint-disable-next-line
  }, []);


  if (state.isLoading) {
    return <InitialLoading />
  }

  if (state.isLoggedIn) {
    return (
      <AppLayout />
    )
  } else {
    return (
      <Page />
    )
  }


}
const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <Router>
      <SnackbarProvider>
        <Root />
        <ConfirmDialog />
      </SnackbarProvider>
    </Router>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
